$(document).ready(function() {
  $('.js-icon-block-expand').on('click', function(e) {
    e.preventDefault()
    $(this)
      .closest('.icon-block')
      .toggleClass('expanded')
      .find('.js-icon-block-description')
      .slideToggle()
  })
})
