export const scrollToAnchor = (hash, e) => {
  if (hash) {
    if (e) e.preventDefault()
    const id = hash.replace('#', '')
    const element = document.getElementById(id)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
      element.id = ''
      window.location.hash = hash
      element.id = id
    }
  }
}
