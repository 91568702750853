import 'malihu-custom-scrollbar-plugin'

$('.timeline').mCustomScrollbar({
  axis: 'x',
  mouseWheel: {
    enable: true,
    axis: 'x',
  },
  autoDraggerLength: false,
  contentTouchScroll: true,
})

var scrollbar = $('.timeline').find('.mCSB_dragger_bar')
$(scrollbar).append('<span class="arrow_right"></span>')
$(scrollbar).append('<span class="arrow_left"></span>')
