var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

/**
 * Returns the value found in obj at path. Delegates to obj.getIn if available.
 * @param obj
 * @param path
 * @returns {*}
 */
function getIn(obj, path) {
    if (typeof obj.getIn === 'function') {
        return obj.getIn(path);
    }
    return path.reduce(function (accum, next) {
        return accum[next];
    }, obj);
}

/**
 * Returns all keys of an object. Delegates to either obj.keys or Object.keys.
 * @param obj
 * @returns {*}
 */

function keys(obj) {
    if (typeof obj.keys === 'function') {
        return Array.from(obj.keys());
    }
    return Object.keys(obj);
}

/**
 * An implementation of breadth-first search. Looks for marker key in the tree.
 * @param tree
 * @param marker
 * @param [maxDepth]
 * @returns {*}
 */
function findMarker(tree, marker) {
    var maxDepth = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 20;

    var rootPath = [];
    var queue = [rootPath];

    var _loop = function _loop() {
        var currentPath = queue.shift();
        if (currentPath.length > maxDepth) {
            return 'continue';
        }
        var currentObj = getIn(tree, currentPath);
        if (currentObj) {
            if (currentObj[marker]) {
                return {
                    v: currentPath
                };
            }
            queue.push.apply(queue, _toConsumableArray(keys(currentObj).map(function (k) {
                return currentPath.concat(k);
            })));
        }
    };

    while (queue.length > 0) {
        var _ret = _loop();

        switch (_ret) {
            case 'continue':
                continue;

            default:
                if ((typeof _ret === 'undefined' ? 'undefined' : _typeof(_ret)) === "object") return _ret.v;
        }
    }
    return false;
}

/**
 * Searches through the given redux store and returns the breakpoints found inside.
 * @arg {object} - The redux state.
 * @returns {object} - The breakpoints associated with the responsive state inside the store.
 */
function getBreakpoints(store) {
    // grab the current state of the store
    var storeState = store.getState();

    var responsiveStatePath = findMarker(storeState, '_responsiveState');

    // if we couldn't find a responsive reducer at the root of the project
    if (!responsiveStatePath) {
        throw new Error('Could not find responsive state reducer. ' + 'If you are still running into trouble, please open a ticket on github.');
    }

    // return the breakpoints in the redux store
    return getIn(storeState, responsiveStatePath).breakpoints;
}

export default getBreakpoints;