import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'

class QuotesSlider extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    var settings = {
      dots: true,
      arrows: false,
      adaptiveHeight: false,
    }

    return (
      <Slider {...settings}>
        {this.props.quotes.map((quote, i) => (
          <div className="quote-slide" key={i}>
            <div
              className="quote-slide__image"
              dangerouslySetInnerHTML={{ __html: quote.image }}
            />
            {quote.content ? (
              <div className="quote-slide__content">{quote.content}</div>
            ) : null}
            {quote.author ? (
              <div className="quote-slide__author">{quote.author}</div>
            ) : null}
          </div>
        ))}
      </Slider>
    )
  }
}

QuotesSlider.propTypes = {
  quotes: PropTypes.array,
}

export default QuotesSlider
