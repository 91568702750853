// Modal hidden event fired
$('.download-modal-class').on('hidden.bs.modal', function() {
  // This would be added to a callback or event function
  // Replace "82" with the ID of your form
  /*
  var gwrf = window.gwrf_82
  if (typeof gwrf != 'undefined') {
    gwrf.reloadForm()
  }
  */

  location.reload()
})

$(document).ready(function() {
  show_categories()
  show_all()
})

function show_all() {
  $('#show_all ').on('click tap', function() {
    // eslint-disable-next-line no-console
    //console.log('test')
    $('.ressource_button').removeClass('active_button')
    $(this).addClass('active_button')
    $('#download_block .ressource_item ').show()
  })
}

function show_categories() {
  $('.ressource_button ').on('click tap', function() {
    // eslint-disable-next-line no-console
    //console.log('click')
    $('.ressource_button').removeClass('active_button')
    $(this).addClass('active_button')
    var id = $(this).attr('id')
    $('#download_block .ressource_item ')
      .show()
      .filter(':not(.' + id + ')')
      .hide()
  })
}
