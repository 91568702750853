import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'

class PartnersSlider extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    var settings = {
      dots: true,
      arrows: false,
      slidesToShow: parseInt(this.props.slidesperpage, 10),
      slidesToScroll: parseInt(this.props.slidesperpage, 10),
      adaptiveHeight: false,
    }

    return (
      <Slider {...settings}>
        {this.props.partners.map((partner, i) => (
          <div className="partner" key={i}>
            <div
              className="partner__logo"
              dangerouslySetInnerHTML={{ __html: partner.logo }}
            />
            {partner.caption ? (
              <span className="partner__caption">{partner.caption}</span>
            ) : null}
          </div>
        ))}
      </Slider>
    )
  }
}

PartnersSlider.propTypes = {
  partners: PropTypes.array,
  slidesperpage: PropTypes.string,
}

export default PartnersSlider
