import $ from 'jquery'

$(document).ready(function() {
  $('.js-event-preview').addClass('animate')
  $('.js-event').on('click', function(e) {
    e.preventDefault()
    var $event = $(e.currentTarget)
    if (!$event.hasClass('active') && $event.find('.event__teaser')) {
      $('.js-event-preview').removeClass('animate')
      setTimeout(function() {
        $event
          .siblings()
          .removeClass('active')
          .end()
          .addClass('active')
        var preview = $event.find('.event__teaser').html()
        $('.js-event-preview')
          .html(preview ? preview : null)
          .delay(300)
          .queue(function() {
            if (window.LazyLoad) {
              new window.LazyLoad({
                elements_selector: '.event-preview__image',
              })
            }
            $(this)
              .addClass('animate')
              .dequeue()
          })
      }, 300)
    }
  })
})
