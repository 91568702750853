$(document).ready(function() {
  var $box = $('.teaserbox--hovered')
  $box.hover(
    function() {
      /* var $content = $(this).find('.rollover_content')
      $content.stop(true, true).slideDown(300, 'linear')*/
    },
    function() {
      /*var $content = $(this).find('.rollover_content')
      $content.stop(true, true).slideUp(300, 'linear')*/
    }
  )
})
