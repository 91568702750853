import React from 'react'
import PropTypes from 'prop-types'

function getCookie(name) {
  const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'))
  if (match) return match[2]
}

function setCookie(name, value, days) {
  let expires = ''
  if (days) {
    let date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = '; expires=' + date.toUTCString()
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/'
}

class Disclaimer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isHidden: false,
    }

    this.handleClick = this.handleClick.bind(this)
  }

  componentDidMount() {
    if (getCookie('disclaimerClosed') !== undefined) {
      this.setState({
        isHidden: true,
      })
    }
  }

  handleClick() {
    if (getCookie('disclaimerClosed') === undefined) {
      setCookie('disclaimerClosed', 'yes', 7)
      document.body.className += ' fadedisclaimer'
    }
  }

  render() {
    const { data } = this.props
    const { isHidden } = this.state
    if (!isHidden) {
      return (
        <div className="dhsv_disclaimer">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-2 imgcon">
                <div style={{ backgroundImage: 'url(' + data.image + ')' }} />
              </div>
              <div className="col-12 col-lg-7 ctncon">
                <p className="head">{data.title}</p>
                <p>{data.text}</p>
              </div>
              <div className="col-12 col-lg-3 linkcon">
                <p className="head">{data.linkhead}</p>
                <p>{data.linktext}</p>
                <a href={data.link.url} className="btn btn-primary">
                  {data.link.title}
                </a>
              </div>
              <span className="close" onClick={this.handleClick} />
            </div>
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}

Disclaimer.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Disclaimer
