var video = document.getElementById('bg-video')
import 'slick-carousel'
// console.log(video) // eslint-disable-line

$(document).ready(function() {
  $('.homeSlider').on('init', function() {
    $(
      '.homeSlider .slick-list .slick-track .slick-slide .slide .content-column__wrapper'
    ).hover(
      function() {
        $(this)
          .closest('.slide')
          .addClass('hovered')
      },
      function() {
        $(this)
          .closest('.slide')
          .removeClass('hovered')
      }
    )
  })

  $('.homeSlider').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    dots: true,
    arrows: true,
    nextArrow:
      '<span class="slick-next"><i class="ion-ios-arrow-right"></i></span>',
    prevArrow:
      '<span class="slick-prev"><i class="ion-ios-arrow-left"></i></span>',
    autoplay: true,
    autoplaySpeed: 12000,
  })

  $('.home-tabs a[data-toggle="tab"]').on('shown.bs.tab', function(e) {
    video.pause()
    $('.home-tabs .video-background').remove()
    // $(window).trigger('resize')
    $($(e.target).attr('href'))
      .find('.carousel')
      .carousel()
  })

  $('.home-tabs a[data-toggle="tab"]').hover(
    function() {
      $(this).addClass('hovered')
    },
    function() {
      $(this).removeClass('hovered')
    }
  )

  $('.video.dhsv-border-blue .video__play-icon.js-video-toggle').on(
    'click tap',
    function(event) {
      // eslint-disable-next-line no-console
      //console.log('Play Button Clicked')
      $(this)
        .closest('.video')
        .addClass('visible')

      $('#video_container iframe')[0].src += '&autoplay=1'
      event.preventDefault()
    }
  )
})
