import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './styles.scss'
import l10n from '../../../shared/l10n'

const { single: { time, price }, common: { moreinfo } } = l10n

class Termin extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showDetails: false,
    }

    this.showDetails = this.showDetails.bind(this)
  }

  showDetails(id) {
    const { onChange } = this.props
    return () => {
      onChange(id)
    }
  }

  render() {
    const { data, showDetails } = this.props

    return (
      <div className={`dhsv_event ${showDetails ? 'open' : ''}`} id={data.id}>
        <div className="dhsv_event_head" onClick={this.showDetails(data.id)}>
          <span className="title h4">{data.title}</span>
          <span className="date h4">{data.date}</span>
          <span className="location h4">{data.location}</span>
          <span className="state" />
        </div>
        <div className="dhsv_event_body">
          {!data.image ? (
            <div className="row">
              <div className="col-12 col-md-8">
                <span className="time h4">
                  <strong>{time}:</strong> {data.time}
                </span>
                <span className="price h4">
                  <strong>{price}:</strong> {data.price}
                </span>
                <div
                  className="teaser h4"
                  dangerouslySetInnerHTML={{ __html: data.teaser }}
                />
                <span className="link" />
              </div>
              <div className="col-12 col-md-4 btnholder">
                <a className="btn btn-primary" href={data.link}>
                  {moreinfo}
                </a>
              </div>
            </div>
          ) : (
            <div className="row withimage">
              <div className="col-12 col-md-8">
                <span className="time h4">
                  <strong>{time}:</strong> {data.time}
                </span>
                <span className="price h4">
                  <strong>{price}:</strong> {data.price}
                </span>
                <div
                  className="teaser h4"
                  dangerouslySetInnerHTML={{ __html: data.teaser }}
                />
                <span className="link" />
                <a className="btn btn-primary" href={data.link}>
                  {moreinfo}
                </a>
              </div>
              <div className="col-12 col-md-4">
                <img src={data.image} />
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

Termin.propTypes = {
  data: PropTypes.object,
  showDetails: PropTypes.bool,
  onChange: PropTypes.func,
}

export default Termin
