import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './styles.scss'

class Event extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showDetails: false,
    }

    this.showDetails = this.showDetails.bind(this)
  }

  showDetails(id) {
    const { onChange } = this.props
    return () => {
      onChange(id)
    }
  }

  render() {
    const { data, showDetails } = this.props

    return (
      <div className={`dhsv_event ${showDetails ? 'open' : ''}`} id={data.id}>
        <div className="dhsv_event_head" onClick={this.showDetails(data.id)}>
          {data.title && (
            <span
              className="title h4"
              dangerouslySetInnerHTML={{ __html: data.title }}
            />
          )}
          {data.time && (
            <span
              className="time h4"
              dangerouslySetInnerHTML={{ __html: data.time }}
            />
          )}
          {data.room && (
            <span
              className="room h4"
              dangerouslySetInnerHTML={{ __html: data.room }}
            />
          )}
          <span className="state" />
        </div>
        <div className="dhsv_event_body">
          <div className="row">
            <div className="col-12 col-md-6">
              <span
                className="description"
                dangerouslySetInnerHTML={{ __html: data.description }}
              />
              {data.referent &&
                data.referent.link && (
                  <span className="h4">
                    <a href={data.referent.link}>{data.referent.title}</a>,{' '}
                    {data.referent.company && data.referent.company.title
                      ? data.referent.company.title
                      : ''}
                  </span>
                )}
              <div className="tags">
                {data.tags &&
                  data.tags.map((tag, i) => (
                    <div key={i} className="tag">
                      {tag.name}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Event.propTypes = {
  data: PropTypes.object,
  showDetails: PropTypes.bool,
  onChange: PropTypes.func,
}

export default Event
