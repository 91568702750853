import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Event from './Event'

class DSAKAGENDA extends Component {
  constructor(props) {
    super(props)

    this.state = {
      day: '1',
    }
  }

  render() {
    const { events, days } = this.props
    const { day } = this.state

    let eventsSorted = events.filter(function(event) {
      if (event.day === day) {
        return event
      }
    })

    return (
      <div className="dhsv_vc_dsak_agenda">
        <label className="toggleday">
          <input
            type={'checkbox'}
            onChange={() =>
              this.setState(prevState => ({
                day: prevState.day === '1' ? '2' : '1',
              }))
            }
          />
          <div className="toggle-wrapper">
            {days.map((day, i) => <div key={i}>{day}</div>)}
          </div>
        </label>
        {eventsSorted.map((event, i) => <Event key={i} event={event} />)}
      </div>
    )
  }
}

DSAKAGENDA.propTypes = {
  events: PropTypes.array.isRequired,
  days: PropTypes.array.isRequired,
}

export default DSAKAGENDA
