import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Events from './Events'

class DSAK extends Component {
  constructor(props) {
    super(props)

    this.state = {
      day: '1',
    }
  }

  render() {
    const { events, days } = this.props
    const { day } = this.state

    return (
      <div className="dhsv_vc_dsak">
        <label className="toggleday">
          <input
            type={'checkbox'}
            onChange={() =>
              this.setState(prevState => ({
                day: prevState.day === '1' ? '2' : '1',
              }))
            }
          />
          <div className="toggle-wrapper">
            {days.length > 0 && days.map((day, i) => <div key={i}>{day}</div>)}
          </div>
        </label>
        {events &&
          Object.values(events).map((group, i) => (
            <div key={i} id={group.id} className="dsak">
              <p className="h3">{group.name}</p>
              {group.events.length > 0 && (
                <Events day={day} events={group.events} />
              )}
            </div>
          ))}
      </div>
    )
  }
}

DSAK.propTypes = {
  events: PropTypes.array.isRequired,
  days: PropTypes.array.isRequired,
}

export default DSAK
