import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './styles.scss'

class Event extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { event } = this.props

    return (
      <div
        className={`dhsv_listevent ${!event.referent ? 'breakstyle' : ''}`}
        id={event.id}
      >
        <div className="row">
          <div className="col-6 col-md-3 --time">
            <span className="h4">{event.time && event.time}</span>
          </div>
          <div className="col-12 col-md-6 --title">
            <span className="h4">{event.title && event.title}</span>
          </div>
          <div className="col-12 col-md-3 --referent">
            {event.referent && (
              <span className="h4">
                <a href={event.referent.link}>{event.referent.title}</a>,{' '}
                {event.referent.company.title}
              </span>
            )}
          </div>
          <div className="col-6 col-md-3 --room">
            <span className="h4">{event.room && event.room}</span>
          </div>
          <div className="col-12 col-md-9 --tags">
            <div className="tags">
              {event.tags &&
                event.tags.map((tag, i) => (
                  <div key={i} className="tag">
                    {tag.name}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Event.propTypes = {
  event: PropTypes.object,
}

export default Event
