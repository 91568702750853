$(document).ready(function() {
  init_entry_wrapper()
})

function init_entry_wrapper() {
  var dhsv_vc_blog_box_height = 0
  var dhsv_vc_blog_box_height_elm = 0
  $('.dhsv_vc_news .entry-wrapper').each(function() {
    dhsv_vc_blog_box_height_elm = $(this).outerHeight()
    if (dhsv_vc_blog_box_height_elm > dhsv_vc_blog_box_height) {
      dhsv_vc_blog_box_height = dhsv_vc_blog_box_height_elm
    }
  })
  $('.dhsv_vc_news .entry-wrapper').each(function() {
    $(this).css('height', dhsv_vc_blog_box_height + 'px')
  })
}
