import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Event from './Event'
import { scrollToAnchor } from '../../util/dom'
import sortBy from 'ramda/es/sortBy'
import compose from 'ramda/es/compose'
import toLower from 'ramda/es/toLower'
import prop from 'ramda/es/prop'
import reverse from 'ramda/es/reverse'
import './styles.scss'
import l10n from '../../shared/l10n'

const { single: { description, date, location } } = l10n

const componentClassname = 'dhsv_events'

class Events extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeEvent: null,
      order: 'ASC',
      orderBy: 'date',
    }

    this.toggleActive = this.toggleActive.bind(this)
    this.handleSort = this.handleSort.bind(this)
  }

  toggleActive(event) {
    const currentEvent = this.state.activeEvent
    this.setState({
      activeEvent: event !== currentEvent ? event : null,
    })

    if (event !== currentEvent) {
      setTimeout(function() {
        scrollToAnchor(event.toString())
      }, 100)
    }
  }

  handleSort(orderBy) {
    const { orderBy: currentSort, order } = this.state

    this.setState({
      orderBy,
    })

    if (currentSort === orderBy) {
      this.setState({
        order: order === 'ASC' ? 'DESC' : 'ASC',
      })
    } else {
      this.setState({
        order: 'ASC',
      })
    }
  }

  render() {
    const { events } = this.props
    const { activeEvent, order, orderBy } = this.state

    let eventsSorted = events

    if (orderBy !== 'date') {
      const sortByStateCaseInsensitive = sortBy(compose(toLower, prop(orderBy)))

      eventsSorted = sortByStateCaseInsensitive(events)
    }

    if (order !== 'ASC') {
      eventsSorted = reverse(eventsSorted)
    }

    return (
      <div className={componentClassname}>
        <div id={componentClassname}>
          <div className="dhsv_events_sort">
            <p className="title h5" onClick={() => this.handleSort('title')}>
              <span
                className={
                  orderBy === 'title' && order !== 'ASC' ? 'reverse' : ''
                }
              >
                {description}
              </span>
            </p>
            <p className="date h5" onClick={() => this.handleSort('date')}>
              <span
                className={
                  orderBy === 'date' && order !== 'ASC' ? 'reverse' : ''
                }
              >
                {date}
              </span>
            </p>
            <p
              className="location h5"
              onClick={() => this.handleSort('location')}
            >
              <span
                className={
                  orderBy === 'location' && order !== 'ASC' ? 'reverse' : ''
                }
              >
                {location}
              </span>
            </p>
          </div>
          {eventsSorted.map((event, i) => (
            <Event
              key={i}
              data={event}
              showDetails={activeEvent === event.id}
              onChange={this.toggleActive}
            />
          ))}
        </div>
      </div>
    )
  }
}

Events.propTypes = {
  events: PropTypes.array.isRequired,
}

export default Events
