import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Event from '../Event'
import sortBy from 'ramda/es/sortBy'
import compose from 'ramda/es/compose'
import toLower from 'ramda/es/toLower'
import prop from 'ramda/es/prop'
import reverse from 'ramda/es/reverse'
import l10n from '../../../shared/l10n'
import './styles.scss'

const { single: { description, time, room } } = l10n

const componentClassname = 'dhsv_events'

class Events extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeEvent: null,
      order: 'ASC',
      orderBy: 'time',
    }

    this.toggleActive = this.toggleActive.bind(this)
    this.handleSort = this.handleSort.bind(this)
  }

  toggleActive(event) {
    const currentEvent = this.state.activeEvent
    this.setState({
      activeEvent: event !== currentEvent ? event : null,
    })
  }

  handleSort(orderBy) {
    const { orderBy: currentSort, order } = this.state

    this.setState({
      orderBy,
    })

    if (currentSort === orderBy) {
      this.setState({
        order: order === 'ASC' ? 'DESC' : 'ASC',
      })
    } else {
      this.setState({
        order: 'ASC',
      })
    }
  }

  render() {
    const { events, day } = this.props
    const { activeEvent, order, orderBy } = this.state

    if (!events) {
      return null
    }

    let eventsSorted = events.filter(function(event) {
      if (event.day === day) {
        return event
      }
    })

    if (orderBy !== 'time') {
      const sortByStateCaseInsensitive = sortBy(compose(toLower, prop(orderBy)))

      eventsSorted = sortByStateCaseInsensitive(eventsSorted)
    }

    if (order !== 'ASC') {
      eventsSorted = reverse(eventsSorted)
    }

    return (
      <div className={componentClassname}>
        <div id={componentClassname}>
          <div className="dhsv_events_sort">
            <p className="title h5" onClick={() => this.handleSort('title')}>
              <span
                className={
                  orderBy === 'title' && order !== 'ASC' ? 'reverse' : ''
                }
              >
                {description}
              </span>
            </p>
            <p className="time h5" onClick={() => this.handleSort('time')}>
              <span
                className={
                  orderBy === 'time' && order !== 'ASC' ? 'reverse' : ''
                }
              >
                {time}
              </span>
            </p>
            <p className="room h5" onClick={() => this.handleSort('room')}>
              <span
                className={
                  orderBy === 'room' && order !== 'ASC' ? 'reverse' : ''
                }
              >
                {room}
              </span>
            </p>
          </div>
          {eventsSorted.length > 0 &&
            eventsSorted.map((event, i) => (
              <Event
                key={i}
                data={event}
                showDetails={activeEvent === event.id}
                onChange={this.toggleActive}
              />
            ))}
        </div>
      </div>
    )
  }
}

Events.propTypes = {
  events: PropTypes.array.isRequired,
  day: PropTypes.string,
}

export default Events
